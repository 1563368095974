import React from 'react';
import ModalCustom from '@components/UI/ModalCustom';
import { useTranslation } from 'react-i18next';

const ModalLotUsers = ({ onConfirm, onCancel, length, type }) => {
  const { t } = useTranslation();

  return (
    <ModalCustom
      isOpen={true}
      title={t('warning')}
      onModalClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className='w-16 h-16 flex flex-col justify-center items-center'
      customClass='shadow-modal h-auto'
      children={
        <div className='text-center whitespace-pre-line'>
          <p className='my-10'>
          {'Vous êtes sur le point de poser un(e) ' + type + ' pour ' + length + ' collaborateurs'}<br/>
          {'Souhaitez-vous continuer ?'}
          </p>
        </div>
      }
    ></ModalCustom>
  );
};

export default ModalLotUsers;
