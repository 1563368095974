import React, { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '@utility/Utils';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCircleXmark,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons';

export const ButtonFirstAction = ({
  children,
  customClass = '',
  type = 'button',
  disabled,
  onClick,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={
        'h-9 flex items-center justify-center rounded-full px-7 bg-primary1  text-md text-white font-medium uppercase hover:bg-lightgrey-900 active:bg-lightgrey-500 disabled:bg-button-disable disabled:text-lightgrey-200 transition-all' +
        ' ' +
        customClass
      }
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonSecondAction = ({
  children,
  customClass = '',
  type,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={
        'flex h-9 items-center justify-center rounded-full px-7  bg-white text-md text-primary1 border-2 border-primary1 font-medium uppercase hover:border-lightgrey-900 hover:text-lightgrey-900 active:border-lightgrey-500 active:text-lightgrey-500 disabled:border-button-disable disabled:text-lightgrey-200 transition-all  ' +
        ' ' +
        customClass
      }
      {...props}
    >
      {children}
    </button>
  );
};

export const ButtonThirdAction = ({
  children,
  customClass = '',
  type,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        'text-primary1 font-semibold underline decoration-1 h-9 hover:text-lightgrey-900 active:text-lightgrey-500 disabled:text-input-disable ',
        customClass
      )}
      {...props}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const ButtonFilled = ({
  children,
  icon,
  onClick,
  type,
  buttonClass = '',
  spanClass = '',
  flat = true,
}) => {
  return (
    <button
      className={classNames(
        flat
          ? 'bg-primary1 text-white hover:bg-button-hover active:bg-button-clicked'
          : 'bg-white text-primary1 ',
        `group uppercase rounded-3xl h-9 cursor-pointer flex items-center ${buttonClass} ${
          icon ? '' : 'justify-center'
        }`
      )}
      type={type ?? 'button'}
      onClick={onClick}
    >
      {icon && (
        <div className='bg-white rounded-full h-[30px] w-[30px] m-1 flex justify-center items-center'>
          {icon}
        </div>
      )}
      <span
        className={`px-2 font-medium text-sm leading-4 tracking-widest ${spanClass}`}
      >
        {children}
      </span>
    </button>
  );
};

export const ButtonDropdown = ({ children, title }) => {
  const { t } = useTranslation();
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <Menu.Button>
        <div className='h-9 flex items-center justify-center rounded-full px-7 bg-primary1  text-md text-white font-medium uppercase hover:bg-lightgrey-900 active:bg-lightgrey-500 disabled:bg-button-disable disabled:text-lightgrey-200 transition-all'>
          {title ? t(title) : t('options')}
          <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1 cursor-pointer'>{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export const FilterButton = ({ selectedOptions, onClick }) => {
  return selectedOptions.length ? (
    <button
      className='flex h-9 p-1 items-center justify-between rounded-full border-2 border-lightgrey-400 w-24 group'
      onClick={onClick}
    >
      <div className='flex items-center gap-1 group-hover:text-white'>
        <FontAwesomeIcon
          icon={faSliders}
          size='lg'
          className='text-lightgrey-400 ml-1 group-hover:text-red'
        />
        <span className='text-primary1 font-semibold text-lg group-hover:text-red'>
          {selectedOptions.length}
        </span>
      </div>
      <div className='flex items-center justify-center bg-primary1 rounded-full w-7 h-7 '>
        <FontAwesomeIcon icon={faXmark} className=' text-white' size='lg' />
      </div>
    </button>
  ) : (
    ''
  );
};

export const ButtonThirdActionWithIcon = ({
  icon,
  onClick,
  children,
  iconClass,
  titleClass,
}) => {
  return (
    <div className='flex items-center group cursor-pointer' onClick={onClick}>
      <span
        className={`w-9 h-9 bg-lightgrey-50 flex items-center justify-center rounded-full mr-2 ${iconClass}`}
      >
        {icon}
      </span>
      <ButtonThirdAction
        className={`underline text-md font-medium group-hover:text-button-hover group-active:text-button-clicked text-lightgrey-600 group-hover:cursor-pointer ${titleClass}`}
      >
        {children}
      </ButtonThirdAction>
    </div>
  );
};

export const ActionButtons = ({ onAcceptClick, onRefuseClick }) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center gap-x-5'>
      <button
        className='flex items-center gap-x-1 w-20 text-primary1 hover:text-primarydark uppercase'
        onClick={(e) => {
          e.stopPropagation();
          onRefuseClick();
        }}
      >
        <FontAwesomeIcon icon={faCircleXmark} className='w-5 h-5' title={t('refuse')} />
        <span>{t('refuse')}</span>
      </button>

      <button
        className='flex items-center gap-x-1 text-primarygreen hover:text-[#466D1D] uppercase '
        onClick={(e) => {
          e.stopPropagation();
          onAcceptClick();
        }}
      >
        <FontAwesomeIcon
          className='w-5 h-5'
          icon={faCircleCheck}
          title={t('accept')}
        />
        <span>{t('accept')}</span>
      </button>
    </div>
  );
};
