import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';

import { classNames } from '@utility/Utils.js';
import { ButtonFilled } from './Buttons';

const ModalCustom = ({
  title,
  isOpen,
  onModalClose,
  onConfirm,
  onCancel,
  children,
  customClass = '',
  containerClass,
  handleClick,
  confirmDisabled,
  outsideClick = true,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10 min-w-[600px]'
        onClose={outsideClick ? onModalClose : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 shadow-xl transition-all sm:my-20 sm:p-6 ${customClass}`}
              >
                <div className='flex items-center justify-between w-full'>
                  <div className='flex gap-2 items-center'>
                    <button
                      type='button'
                      className='rounded-md bg-white text-gray-700 hover:text-gray-500 '
                      onClick={onModalClose}
                    >
                      <ChevronLeftIcon
                        className='h-6 w-6 '
                        aria-hidden='true'
                        onClick={onModalClose}
                      />
                    </button>

                    {title && (
                      <Dialog.Title
                        as='h3'
                        className='text-lg font-medium leading-6 text-gray-900 text-ellipsis'
                      >
                        {title}
                      </Dialog.Title>
                    )}
                  </div>
                  {handleClick && (
                    <ButtonFilled
                      buttonClass='px-8 py-4'
                      type='button='
                      onClick={handleClick}
                    >
                      {t('relaunch')}{' '}
                    </ButtonFilled>
                  )}
                </div>

                <div className={containerClass}>
                  <div className='text-center sm:mt-0 sm:text-left'>
                    {children}
                  </div>
                </div>
                <div
                  className={classNames(
                    onConfirm && onCancel
                      ? 'mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:w-full justify-center'
                      : 'hidden'
                  )}
                >
                  {onConfirm && (
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 '
                      onClick={onConfirm}
                      disabled={confirmDisabled}
                    >
                      {t('confirm')}
                    </button>
                  )}
                  {onCancel && (
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm'
                      onClick={onCancel}
                    >
                      {t('cancel')}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalCustom;
